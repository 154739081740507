body {
    font-family: "Plus Jakarta Sans", sans-serif !important;
}
.theme-color {
    color: #743CDB;
}
.text_primary {
    color: #743CDB !important;
}
.text_orange {
    color: #f99705 !important;
}
.w-50 {
    width: 50%;
}
.rounded-lg-tcul{
    border-radius: 1.55rem;
    min-width: 50px;
}

.card-heder.tcul{
    background-color: #743CDB!important;
    color: #fff;
}

.light.btn-theme {
    background-color: #ebeaef;
    border-color: #d9d7e1;
    color: #743CDB;
}

.light.btn-theme:hover {
    background-color: #743CDB;
    border-color: #743CDB;
    color: #fff;
}

.btn-theme {
    min-width: 2.5rem;
    padding: 0.4375rem;
    height: 2.5rem;
    min-height: 2.5rem;
}

.light.btn-theme g [fill] {
    fill: #743CDB;
}

.light.btn-theme:hover g [fill] {
    fill: #fff;
}

.fs_tcul{
    font-size: 13px;
}

.fs_tcul-18{
    font-size: 18px;
}

.text-theme {
    color: #743CDB;
}

.deznav .metismenu > li:hover > a, .deznav .metismenu > li:focus > a {
    color: #ffab32;
}

.icon-container {
    display: flex;
    align-items: center;
    gap: 10px; /* Space between icons */
}

.icon {
    color: #25D366; /* WhatsApp green color for the WhatsApp icon */
    font-size: 24px;
    text-decoration: none;
    transition: color 0.3s;
}

.icon i {
    color: inherit;
}

.icon:hover {
    color: #128C7E; /* Darker green on hover for WhatsApp */
}

.icon + .icon {
    color: #743CDB; /* Email icon color */
}

.icon + .icon:hover {
    color: #6e64b4; /* Darker blue on hover for email */
}

.page-titles h2 {
    margin-bottom: 0;
    margin-top: 0;
    color: var(--primary);
    font-size: 1.8rem;
}

.light.btn-warning-tcul {
    border-color: #fff0da;
    color: #fff0da;
}

.deznav .metismenu > li > a i {
    color: #743CDB;
}

.deznav .metismenu > li > a i:hover{
    color: #ffab32;
}

.book-box {
    background: #ebeaef;
}

/* alternate mobile number start */

.btn-primary-tcul {
    display: inline-flex;
    align-items: center;
    font-size: 16px;
    gap: 5px; /* Space between icon and text */
    cursor: pointer;
    color: #f99705;
}

#mobile-number-field-container {
    margin-top: 20px;
    position: relative;
}

.position-relative {
    position: relative;
}

#remove-docs-btn {
    position: absolute;
    top: 10px;
    right: 0px;
    background-color: #ff4d4f;
    border: none;
    color: white;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
#remove-mobile-btn{
    position: absolute;
    top: 10px;
    right: -10px;
    background-color: #ff4d4f;
    border: none;
    color: white;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.remove-field-btn i {
    font-size: 12px;
}

.remove-field-btn:hover {
    background-color: #e04143;
}

/* alternate mobile number end */

.upload-field-container {
    justify-content: center;
    width: 100%;
    display: inline-flex;
    align-items: center;
    border-radius: 8px;
    padding: 10px;
    cursor: pointer;
    transition: border-color 0.3s;
}

.upload-label {
    display: inline-flex;
    align-items: center;
    font-size: 16px;
    color: #f99705;
    cursor: pointer;
    gap: 10px; /* Space between icon and text */
}

.upload-label i {
    font-size: 16px;
}

.image-name {
    margin-top: 10px;
    font-size: 14px;
    color: #333;
}

/* Center-align table headers */
thead th {
    text-align: start;
    vertical-align: middle;
}

/* Additional styles if needed */
thead th .rank-ic {
    display: inline-block; /* Ensures that the span is treated as inline-block */
}

.accordion-header-indicator {
    left: 13%;
}

.accordion-header-text.tcul::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
}

.nav_copyright{
    /* bottom: -20px; */
    position: absolute;
    bottom: 0;
}
[data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu > li > a {
    padding: 12px 20px;
    border-radius: 0.70rem;
}
.btn-check:focus + .btn, .btn:focus {
    box-shadow: 0 0 0 0.25rem rgba(54, 48, 98, 0) !important;
}
/* Auth Pages */
.authincation {
    background-color: #f9f7ff !important;
}
.authincation-content {
    border-radius: 24px !important;
}
/* Dashboard */
body {
    background-color: #f9f7ff !important;
}
.footer {
    background-color: #3f0071 !important;
}
.card{
    border-radius: 24px !important;
}
[data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu > li {
    padding: 0 20px;
}
.nav-header .brand-title {
    margin-left: 0px;
    max-width: 100%;
}
/* Font Weight */
.fw_300 {
    font-weight: 300;
}
.fw_400 {
    font-weight: 400;
}
.fw_500 {
    font-weight: 500;
}
.fw_600 {
    font-weight: 600;
}
.fw_700 {
    font-weight: 700;
}
.fw_800 {
    font-weight: 800;
}


.fs_1 {
    font-size: .625rem!important
}

.fs_2 {
    font-size: .75rem!important
}

.fs_3 {
    font-size: .875rem!important
}

.fs_4 {
    font-size: 1rem!important
}

.fs_5 {
    font-size: 1.125rem!important
}

.fs_6 {
    font-size: 1.25rem!important
}

.fs_7 {
    font-size: calc(1.275rem + .3vw)!important
}

.fs_8 {
    font-size: calc(1.3125rem + .75vw)!important
}

.fs_9 {
    font-size: calc(1.35rem + 1.2vw)!important
}

.fs_10 {
    font-size: calc(1.375rem + 1.5vw)!important
}

.fs_11 {
    font-size: calc(1.40625rem + 1.875vw)!important
}
.fs_10 {
    font-size: 10px !important;
}
.fs_12 {
    font-size: 12px !important;
}
.fs_13 {
    font-size: 13px !important;
}
.fs_14 {
    font-size: 14px !important;
}
.fs_15 {
    font-size: 15px !important;
}
.fs_16 {
    font-size: 16px !important;
}
.fs_17 {
    font-size: 17px !important;
}
.fs_18 {
    font-size: 18px !important;
}
.fs_19 {
    font-size: 19px !important;
}
.fs_20 {
    font-size: 20px !important;
}
.fs_21 {
    font-size: 21px !important;
}
.fs_22 {
    font-size: 22px !important;
}
.text-left {
    text-align: left!important
}
.text-right {
    text-align: right!important
}
.fst-italic {
    font-style: italic!important
}

.fst_normal {
    font-style: normal!important
}

.fw_lighter {
    font-weight: 300!important
}

.fw_light {
    font-weight: 300!important
}

.fw_normal {
    font-weight: 400!important
}

.fw_medium {
    font-weight: 500!important
}

.fw_semibold {
    font-weight: 600!important
}

.fw_bold {
    font-weight: 500!important
}

.fw_bolder {
    font-weight: 700!important
}

/* mobile media query */
@media only screen and (max-width: 575px) {
    .deznav .metismenu li i {
        padding-right: 10px !important;
    }
}
/* Media Query */
@media only screen and (max-width: 35.9375rem) {
    .accordion-header-text{
        position: relative;
        left: 55%;
    }
    .accordion-header-indicator {
        left: 80%;
    }
}
/*tablet responsive*/

@media (max-width: 768px) {
    .accordion-header-indicator {
        left: 90%;
    }

    .accordion-header-text {
        position: relative;
        left: 75%;
    }
}

@media (min-width: 1024px) {
    .accordion-header-indicator {
        left: 90%;
    }

    .accordion-header-text {
        position: relative;
        left: 85%;
    }
}
@media only screen and (max-width: 1199px) {
    [data-header-position="fixed"] .content-body {
        min-height: 98vh !important;
    }
}

@media (max-width: 768px) {
    .authincation-content {
        padding: 20px;
    }
    .auth-form {
        padding: 15px;
    }
    .text-center img {
        max-width: 100%;
        height: auto;
    }
    .btn-block {
        width: 100%;
    }
}
.theme-color {
    color: #4f4597;
}

.profile-container {
    position: relative;
    display: inline-block;
    text-align: center;
}

.ds-img-profile {
    display: block;
    width: 100px;
    height: 100px;
    /* object-fit: cover; */
    border-radius: 50%;
}

.upload-icon {
    position: absolute;
    bottom: -10px; /* Adjust position slightly below the image */
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 50%;
    padding: 0px;
    border: 2px solid white; /* Adding a border */
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.custom-file-upload {
    cursor: pointer;
    color: white;
    font-size: 18px;
    line-height: 0;
    margin-bottom: 0px;
}

.upload-icon input[type="file"] {
    display: none;
}

